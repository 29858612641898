/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.divider {
  height: 50px;
  /* margin: 50px 0; */
  text-align: center;
  font-size: 28px;
  position: relative;
}

.divider:after {
  content: '';
  width: 100%;
  border-bottom: solid 1px rgb(209 213 219);
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

.divider-text {
  display: inline-block;
  background-color: white;
  z-index: 10;
  padding: 0 10px 0 10px;
  position: relative;
  margin: 0;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.btn-flashy {
  padding: 1rem 2rem;
  font-size: 1rem;
  color: #FFF;
  background-color: #6366F1; /* Indigo */
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  outline: none;
  position: relative;
  overflow: hidden;
  transition: transform 200ms ease-in-out, background-color 200ms ease-in-out;
  box-shadow: 0 4px 14px 0 rgba(99, 102, 241, 0.5);
}



.btn-flashy span {
  position: relative;
  z-index: 1;
}

.btn-flashy:hover {
  transform: translateY(-5px);
}

/* Let's also add a "focus" state for accessibility */
.btn-flashy:focus {
  outline: 2px solid #FFF;
}